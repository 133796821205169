import { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  sendPay,
  sendUserEncodeData,
  SendPayParams
} from '@root/api/getUserInfo';
import { formatSendCardNuber, formatSendExpiry } from '@root/utils/convertors';
import { getFakeForm } from '@root/services/fakeForm';
import { detectUserDevice } from '@root/utils/detectUserDevice';
import axios from 'axios';
import { useUserData } from '@hooks/useUserData';

export type OnSubmitParams = {
  merchant?: string;
  token?: string;
  redirectPaths: {
    success?: (param?: string) => string;
    failed: (param?: string) => string;
  };
};

export type OnSubmit = (data: SubmitParams) => Promise<void>;

export type SubmitParams = Omit<SendPayParams, 'session' | 'merchant'>;

const getMerchantAndSesstion = (data: {
  id: string | undefined;
  merchant: string | undefined;
  searchParams: URLSearchParams;
}) => {
  const { id, searchParams } = data;
  const session = id || searchParams.get('session') || '';
  const merchant = data.merchant || searchParams.get('merchant') || '';
  return { session, merchant };
};

export const useOnSubmit = (params: OnSubmitParams) => {
  const { redirectPaths, merchant, token } = params;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams({ session: '', merchant: '' });
  const { id } = useParams();
  const { userData } = useUserData(id);

  const merchanAndSession = useMemo(
    () =>
      getMerchantAndSesstion({
        id,
        merchant,
        searchParams
      }),
    [id, merchant, searchParams]
  );

  useEffect(() => {
    axios({
      method: 'get',
      url: 'https://get.geojs.io/v1/ip/geo.json'
    }).then((res) => {
      const { data } = res;
      const userDataBase64 = btoa(
        JSON.stringify({ browser: { ...detectUserDevice() }, geo: { ...data } })
      );
      if (merchanAndSession.session) {
        return sendUserEncodeData(merchanAndSession.session, {
          frame: userDataBase64
        });
      }
    });
  }, []);

  const onSubmit = (data: SubmitParams) => {
    const { card_number, expiry } = data;

    const sendedData: SendPayParams = {
      ...data,
      ...merchanAndSession,
      card_number: formatSendCardNuber(card_number),
      expiry: formatSendExpiry(expiry)
    };

    const cardMask = userData && userData.cardMask ? userData.cardMask : '';

    if (token && cardMask == card_number) {
      sendedData.token = token;
    }
    return sendPay(sendedData)
      .then((res) => {
        const { url, creq, threeDSSessionData } = res;
        if (creq && threeDSSessionData) {
          getFakeForm({ url, creq, threeDSSessionData });
        } else {
          window.location.replace(url);
        }
      })
      .catch(() => {
        navigate(redirectPaths.failed(id));
      });
  };

  return { onSubmit };
};
