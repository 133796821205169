import { lazy } from 'react';
import { CREDIT365_MERCHANT } from '@root/constants/paths';
import { getCredit365ContentData } from '@root/constants/formData';
import { getCredit365VerifyRoutes } from './credit365VerifyRoutes';
import { getCredit365PayRoutes } from './credit365PayRoutes';

const Layout = lazy(() => import('@pages/credit365/Layout'));

const { header } = getCredit365ContentData();

export const credit365Routes = {
  path: CREDIT365_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getCredit365VerifyRoutes(), ...getCredit365PayRoutes()]
};
