import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '@root/images/custom/success.svg';
import { ReactComponent as ErrorIcon } from '@root/images/custom/error-icon.svg';
import { useSearchParams } from 'react-router-dom';
import {
  downloadInvoice,
  DownloadInvoiceParams,
  getTransactionStatus,
  sendRetryPayment,
  SendRetryPaymentParams,
  TranStatusResponse
} from '@root/api/getUserInfo';
import { getFormattedPriceLocale } from '@root/utils/convertors';
import LoaderComponent from '@comp/LoaderComponet';

const Status = () => {
  const { t } = useTranslation(['common']);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('tranId');
  const [isNotFound, setIsNotFound] = useState(false);
  const [data, setData] = useState<TranStatusResponse | null>(null);

  useEffect(() => {
    if (id) {
      getTransactionStatus(id)
        .then((data) => setData(data))
        .catch((reason) => {
          console.log(reason);
          if (reason.response!.status === 404) {
            setIsNotFound(true);
          }
        });
    }
  }, [id]);

  const getTitleComponent = (data: TranStatusResponse) => {
    if (data.statusCode === '1000') {
      return {
        title: (
          <div className="status-title-container">
            <div className="icon-cotainer">
              <SuccessIcon />
            </div>
            <h1 className="status-title">{data.codeDesc}</h1>
          </div>
        ),
        content: (
          <div className="cardProof__list-row cardProof__list-row--huge-pd">
            <p className="cardProof__list-item">Оплачено</p>
            <p className="cardProof__list-item">
              {getFormattedPriceLocale(data.amount)}
            </p>
          </div>
        )
      };
    }

    const errorInfo =
      data.statusDescription !== null &&
      data.statusDescription.split('&NewLine;');

    return {
      title: (
        <div className="status-title-container">
          <div className="icon-cotainer">
            <ErrorIcon />
          </div>
          <h1 className="status-title">{data.codeDesc}</h1>
        </div>
      ),
      content: errorInfo ? (
        <ul className="status-error-list">
          {errorInfo.map((info) => (
            <li
              key={info}
              className={`status-error-list__item ${
                errorInfo.length === 1
                  ? 'status-error-list__item--only-one'
                  : ''
              }`.trim()}
            >
              {info}
            </li>
          ))}
        </ul>
      ) : null
    };
  };

  if (isNotFound) {
    return (
      <section className="cardProof">
        <form
          className="container-s container-s--min-pd"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        ></form>
      </section>
    );
  }

  if (data) {
    return (
      <section className="cardProof">
        <form
          className="container-s container-s--min-pd"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
          onSubmit={(evt) => {
            evt.preventDefault();
            window.location.replace(data.merchantLink);
          }}
        >
          {getTitleComponent(data).title}
          <div className="cardProof__list cardProof__list--no_border">
            {getTitleComponent(data).content}
          </div>
          {data.statusCode == '3800' && (
            <iframe
              style={{
                width: 'calc(100vw - 40px)',
                height: 'calc(calc(100vw - 40px) / 16 * 9)',
                maxWidth: '550px',
                maxHeight: 'calc(550px / 16 * 9)',
                marginBottom: '40px',
                marginTop: '20px'
              }}
              src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FKaspi.kz%2Fvideos%2F467534424331819%2F&width=700&show_text=false&height=700&appId"
            ></iframe>
          )}
          {data.shouldDisplayBackToSiteButton && (
            <button className="btn" type="submit">
              {t('common:back_to_site')}
            </button>
          )}

          {data.statusCode == '1000' && data.tranType === 'c2a' && (
            <button
              className="btn"
              type="submit"
              onClick={(evt) => {
                evt.preventDefault();

                const sendedData: DownloadInvoiceParams = {
                  uuid: data?.tranId
                };

                downloadInvoice(sendedData).then((res) => {
                  const url = window.URL.createObjectURL(new Blob([res]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute(
                    'download',
                    `transaction_invoice_${data?.tranId}.pdf`
                  );
                  document.body.appendChild(link);
                  link.click();
                });
              }}
            >
              {t('common:download_invoice')}
            </button>
          )}
          {data.shouldDisplayRetryButton && (
            <button
              className="btn"
              type="submit"
              onClick={(evt) => {
                evt.preventDefault();

                const sendedData: SendRetryPaymentParams = {
                  tranId: data?.tranId
                };

                sendRetryPayment(sendedData).then((res) => {
                  const { frameUrl } = res;
                  if (frameUrl) {
                    window.location.replace(frameUrl);
                  }
                });
              }}
            >
              {t('common:retry')}
            </button>
          )}
        </form>
      </section>
    );
  }

  return <LoaderComponent />;
};

export default Status;
