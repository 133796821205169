import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import CustomField from '../CustomField';
import {
  useValidation,
  excludeValidationForSomeCards
} from '@root/utils/validation';
// import CustomCheckBox from '../CustomCheckbox';
import PayImgBox from '@root/components/PayImgBox';
import CvvInfo from '@comp/CvvInfo';
import { ContentData } from '@root/constants/formData/types';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import CustomWrapperFields from '@comp/CustomWrapperFields';
import RawFields from '@comp/RawFields';
import UserAgreement from '@root/components/UserAgreement';
import PayButtons from '@comp/PayButtons';
import { OnSubmit } from '@hooks/useOnSubmit';
import { UserData } from '@root/hooks/useUserData';
//import CustomCheckBox from '@comp/CustomCheckbox';
import style from '@scss/credit7/index.module.scss';

export type CustomFormInputs = {
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  holder: string;
  email: string;
};

type CustomFormProps = {
  userData: UserData;
  onSubmit: OnSubmit;
  children: React.ReactNode | undefined;
  formData: {
    title: string;
  };
  mainContent: ContentData['main'];
};

const CustomForm = (props: CustomFormProps) => {
  const {
    userData: {
      expiry,
      cardMask,
      apayEnabled,
      gpayEnabled,
      options,
      fee,
      fullAmount,
      payAmount,
      tranType,
      merchantHost
    },
    onSubmit,
    children,
    formData
  } = props;

  const { cardValidation, setIsValidate, isValidate } = useValidation({
    noValidateExpiry: Boolean(expiry),
    noValidateCardNumber: Boolean(cardMask),
    validateEmail: true,
    validateHolder: true
  });

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
    watch
  } = useForm<CustomFormInputs>({
    resolver: cardValidation,
    defaultValues: {
      save: 'true',
      card_number: cardMask || '',
      expiry: expiry || '',
      cvv: '',
      holder: ''
    }
  });

  const { t } = useTranslation(['common']);

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation]);

  return (
    <section className="cardProof">
      <form className="container-s" onSubmit={handleSubmit(onSubmit)}>
        <h1>{t(`common:${formData.title}`)}</h1>
        {children ?? children}
        <CustomField
          disabled={Boolean(cardMask)}
          control={control}
          name={'card_number'}
          label={t('common:card_number')}
          mask={cardMask ? '9999 99** **** 9999' : '9999 9999 9999 9999'}
          error={errors['card_number']}
          inputMode={'numeric'}
        />
        <div className="input-row">
          <CustomField
            disabled={Boolean(expiry)}
            control={control}
            name={'expiry'}
            label={t('common:expiry')}
            mask={'99/99'}
            error={errors['expiry']}
            inputMode={'numeric'}
          />
          <CustomField
            control={control}
            name={'cvv'}
            label="CVV"
            mask={'999'}
            comp={<CvvInfo />}
            error={errors['cvv']}
            inputMode={'numeric'}
          />
        </div>
        <CustomWrapperFields
          label={t('common:holder')}
          error={errors['holder']}
        >
          <RawFields control={control} name={'holder'} inputMode={'text'} />
        </CustomWrapperFields>
        {/* <CustomCheckBox
          control={control}
          name={'userAgreedWithPolicy'}
          defaultValue={true}
        />*/}
        {tranType == 'c2a' && (
          <PayButtons
            isApple={apayEnabled}
            isGoogle={gpayEnabled}
            onSubmit={onSubmit}
            payInfo={{
              fee,
              clientName: options.clientName,
              agreementId: options.agreementId,
              merchantHost,
              fullAmount,
              payAmount
            }}
          />
        )}
        <div className="button-spin">
          <button
            disabled={isSubmitting}
            className={`${style.btn} ${style.btn__purple}`}
            type="submit"
          >
            {t('common:confirm')}
          </button>
          {isSubmitting && (
            <Oval wrapperClass="button-spin__spinner" strokeWidth={5} />
          )}
        </div>
        <UserAgreement />
        <PayImgBox />
      </form>
    </section>
  );
};

export default CustomForm;
