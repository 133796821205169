import React, { useEffect, useState } from 'react';
import style from '@root/scss/credit7/index.module.scss';
import { useForm } from 'react-hook-form';
import { Field } from './Field';
import {
  useValidation,
  excludeValidationForSomeCards
} from '@root/utils/validation';
import { ContentData } from '@root/constants/formData/types';
import iconpay1 from '@root/images/acredit/logo.svg';
import iconpay2 from '@root/images/k7icons/logo_ico_2.svg';
import iconpay3 from '@root/images/k7icons/logo_ico_3.svg';
import iconpay4 from '@root/images/k7icons/logo_ico_4.svg';
import InputWrapper from './InputWrapper';
import { Oval } from 'react-loader-spinner';
import { useTranslation } from 'react-i18next';
import RawFields from '@comp/RawFields';
import UserAgreement from '@comp/UserAgreement';
import PayButtons from '@comp/PayButtons';
import { OnSubmit } from '@hooks/useOnSubmit';
import { UserData } from '@root/hooks/useUserData';
import CustomCheckBox from '@comp/CustomCheckbox';
import { cardMaskByNumber } from '@root/functions/cardMaskByNumber';

export type K7FormInputs = {
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  email: string;
  holder: string;
};

type K7FormProps = {
  onSubmit: OnSubmit;
  userData: UserData;
  children: React.ReactNode | undefined;
  formData: {
    title: string;
  };
  mainContent: ContentData['main'];
  expiry?: string;
  cardMask?: string;
};

export const Form = (props: K7FormProps) => {
  const {
    userData: {
      expiry,
      cardMask,
      apayEnabled,
      merchantHost,
      gpayEnabled,
      shouldDisplayAgreementDetails,
      options,
      fee,
      fullAmount,
      payAmount,
      tranType,
      isExtension
    },
    onSubmit,
    children
  } = props;

  const [masked, setMasked] = useState(Boolean(cardMask));
  const [cardNumberMask, setCardNumberMask] = useState(
    cardMask ? '9999 99** **** 9999' : '9999 9999 9999 9999*'
  );

  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateExpiry: Boolean(expiry),
    noValidateCardNumber: Boolean(cardMask),
    validateEmail: true,
    validateHolder: true
  });

  const {
    handleSubmit,
    watch,
    clearErrors,
    control,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<K7FormInputs>({
    resolver: cardValidation,
    defaultValues: {
      save: 'true',
      card_number: cardMask || '',
      expiry: expiry || '',
      cvv: '',
      holder: ''
    }
  });

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate]);

  const watchFields = watch('card_number');

  React.useEffect(() => {
    if (masked && watchFields != cardMask) {
      setMasked(false);
      setValue('card_number', '');
      setValue('expiry', '');
    }
  }, [masked, watchFields, cardMask]);

  React.useEffect(() => {
    const mask = cardMaskByNumber(watchFields, masked);
    if (mask != cardNumberMask) {
      setCardNumberMask(mask);
    }
  }, [watchFields]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation]);

  const { t } = useTranslation(['common']);

  return (
    <section className={style.blockForm}>
      <form className={style.container_540} onSubmit={handleSubmit(onSubmit)}>
        {!isExtension && <h1>Погашение микрокредита</h1>}
        {isExtension && <h1>Отсрочка даты платежа по микрокредиту</h1>}
        {children ?? children}
        <div className={style.blockForm__form}>
          <Field
            control={control}
            name={'card_number'}
            label={t('common:card_number')}
            mask={cardNumberMask}
            error={errors['card_number']}
            isIcon={true}
          />
          <div className={style.inout_row}>
            <Field
              disabled={Boolean(expiry)}
              control={control}
              name={'expiry'}
              label={t('common:expiry')}
              mask={'99/99'}
              error={errors['expiry']}
            />
            <Field
              control={control}
              name={'cvv'}
              label="CVV"
              mask={'999'}
              error={errors['cvv']}
              tooltip={
                <>
                  <h3>Где взять CVV?</h3>
                  <p>
                    Это трехзначный защитный код, который находится на обратной
                    стороне банковской карты рядом с полем для подписи.
                  </p>
                </>
              }
            />
          </div>
          <InputWrapper
            label={t('common:holder')}
            error={errors['holder']}
            control={control}
            name="holder"
          >
            <RawFields
              control={control}
              name={'holder'}
              inputMode={'text'}
              classNames={{ error: style.error_text }}
            />
          </InputWrapper>
          {tranType == 'c2a' && (
            <>
              {shouldDisplayAgreementDetails && (
                <CustomCheckBox
                  control={control}
                  name={'userAgreedWithPolicy'}
                  defaultValue={true}
                />
              )}
              <PayButtons
                isApple={apayEnabled}
                isGoogle={gpayEnabled}
                onSubmit={onSubmit}
                payInfo={{
                  fee,
                  clientName: options.clientName,
                  agreementId: options.agreementId,
                  merchantHost,
                  fullAmount,
                  payAmount
                }}
              />
            </>
          )}
        </div>
        <div className="button-spin">
          <button
            className={`${style.btn} ${style.btn__purple}`}
            disabled={isSubmitting}
            type="submit"
          >
            {t('common:confirm')}
          </button>
          {isSubmitting && (
            <Oval
              wrapperClass="button-spin__spinner"
              strokeWidth={5}
              color="#fff"
            />
          )}
        </div>
        <UserAgreement />
        <div className={style.blockForm__logotypes}>
          <img src={iconpay1} alt="" />
          <img src={iconpay2} alt="" />
          <img src={iconpay3} alt="" />
          <img src={iconpay4} alt="" />
        </div>
      </form>
    </section>
  );
};
