import GooglePayButton from '@google-pay/button-react';
import { OnSubmit } from '@hooks/useOnSubmit';
import { PayInfo } from '../PayButtons';
import './style.scss';

interface GooglePayButtonComponentProps {
  payInfo: PayInfo;
  onSubmit: OnSubmit;
}

function GooglePayButtonComponent({
  payInfo,
  onSubmit
}: GooglePayButtonComponentProps) {
  const { fullAmount } = payInfo;
  const formattedAmount = fullAmount.replace(/\s/g, '').replace(',', '.');

  return (
    <GooglePayButton
      environment="PRODUCTION"
      buttonSizeMode="fill"
      buttonType="plain"
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA']
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'paytech',
                gatewayMerchantId: 'paytech'
              }
            }
          }
        ],
        merchantInfo: {
          merchantId: 'BCR2DN4T66G5ZOTT',
          merchantName: 'NewPay'
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: formattedAmount,
          currencyCode: 'KZT',
          countryCode: 'KZ'
        }
      }}
      onLoadPaymentData={(paymentRequest) => {
        const data = {
          card_number: '',
          expiry: '',
          cvv: '',
          save: 'true',
          googlePayToken:
            paymentRequest.paymentMethodData.tokenizationData.token
        };
        onSubmit(data);
      }}
    />
  );
}

export default GooglePayButtonComponent;
