import { lazy } from 'react';
import { ONECREDIT_MERCHANT } from '@root/constants/paths';
import { getOneCreditContentData } from '@root/constants/formData';
import { getOneCreditVerifyRoutes } from './oneCreditVerifyRoutes';
import { getOneCreditPayRoutes } from './oneCreditPayRoutes';

const LayoutOneCredit = lazy(() => import('@pages/onecredit/LayoutOneCredit'));

const { header } = getOneCreditContentData();

export const oneCreditRoutes = {
  path: ONECREDIT_MERCHANT,
  element: <LayoutOneCredit headerContent={header} />,
  children: [...getOneCreditVerifyRoutes(), ...getOneCreditPayRoutes()]
};
