import { instanceApi } from './index';
import qs from 'qs';

function getFormData<DataType>(data: DataType) {
  return qs.stringify(data);
}

export type UserInfoOptions = {
  agreementId: number;
  clientName: string;
  merchant: string;
  isExtension: boolean;
  isRestructure: boolean;
  extTerm?: number;
  pageTitle?: 'partPay' | 'extention' | 'pay';
  buttonTitle?: 'partPay' | 'extention' | 'pay';
};

export type GetUserInfo = {
  amount: number;
  csrf: string;
  description: string;
  merchantHost?: string;
  isExtension?: boolean;
  editCardMask: boolean;
  fee: number;
  fullAmount: number;
  lang: 'ru' | 'uk';
  pan?: string;
  tranId: number;
  uuid: string;
  options: UserInfoOptions;
  apayEnabled: boolean;
  gpayEnabled: boolean;
  shouldDisplayAgreementDetails?: boolean;
  expiry?: string;
  token?: string;
  tranType?: string;
};

export const getUserInfo = (uuid: string) =>
  instanceApi
    .get<GetUserInfo>(`/frame/frontend/details/${uuid}`, {
      method: 'GET'
    })
    .then((data) => data.data);

export type SendPayParams = {
  session: string;
  merchant: string;
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  email?: string;
  payer?: string;
  amount?: string;
  token?: string;
  applePayToken?: string;
  googlePayToken?: string;
};

export type SendRetryPaymentParams = {
  tranId: string;
};

export type DownloadInvoiceParams = {
  uuid: string;
};

type SendPayPost = {
  url: string;
  creq?: string;
  threeDSSessionData?: string;
};

type SendRetryPaymentPost = {
  frameUrl: string;
};

export const sendPay = (data: SendPayParams) => {
  const { card_number, ...rest } = data;
  const formData = getFormData({ ...rest, numbercard: card_number });
  return instanceApi
    .post<SendPayPost>('/pay/v2', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((data) => data.data);
};

export const sendRetryPayment = (data: SendRetryPaymentParams) =>
  instanceApi
    .post<SendRetryPaymentPost>(
      `/pay/v2/retry/${data.tranId}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    .then((data) => data.data);

export const downloadInvoice = (data: DownloadInvoiceParams) =>
  instanceApi
    .get(`/frame/frontend/invoice/${data.uuid}/download`, {
      responseType: 'blob'
    })
    .then((data) => data.data);

export const sendUserEncodeData = (id: string, data: { frame: string }) =>
  instanceApi.post(`/frame/frontend/details/status/${id}`, data);

export type TranStatusResponse = {
  statusCode: string;
  codeDesc: string;
  receiptLink: string;
  merchantLink: string;
  statusDescription: null | string;
  amount: number;
  shouldDisplayBackToSiteButton: boolean;
  shouldDisplayRetryButton: boolean;
  tranId: string;
  tranType: string;
};

export const getTransactionStatus = (id: string) =>
  instanceApi
    .get<TranStatusResponse>(`/frame/frontend/status/${id}`)
    .then((data) => data.data);
