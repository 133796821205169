/* eslint-disable prettier/prettier */
import customLogo from '@root/images/custom/custom-logo.png';
import credit365Logo from '@root/images/credit365/logo.svg';
import logoAcredit from '@root/images/acredit/logo.png';
import logoOneCredit from '@root/images/onecredit/200_60.png';
import logoInstallment from '@root/images/installment/logo.svg';
import { ContentData } from './types';

export const getCustomContentData = (): ContentData => ({
  header: {
    title: 'NewPay',
    icon: customLogo
  },
  main: {},
  footer: {
    email: 'info@newpay.kz',
    firmInfo:
      'ТОО "Нью Пей" БИН 221040018026. ТОО «Нью Пей» с учетной регистрацией в качестве платежной организации, присвоен ' +
      'регистрационный номер № 02-22-142 и записан в Реестр платежных организаций.' +
      '© 2024 ® Newpay. Все права защищены."'
  }
});

export const getNewPayContentData = (): ContentData => ({
  header: {
    title: 'NewPay',
    icon: customLogo
  },
  main: {},
  footer: {
    email: 'info@newpay.kz',
    firmInfo:
      'ТОО "Нью Пей" БИН 221040018026. ТОО «Нью Пей» с учетной регистрацией в качестве платежной организации, присвоен ' +
      'регистрационный номер № 02-22-142 и записан в Реестр платежных организаций.' +
      '© 2024 ® Newpay. Все права защищены."'
  }
});

export const getAcreditContentData = (): ContentData => ({
  header: {
    title: 'ACredit',
    icon: logoAcredit
  },
  main: {},
  footer: {
    email: 'info@newpay.kz',
    firmInfo:
      'ТОО "Нью Пей" БИН 221040018026. ТОО «Нью Пей» с учетной регистрацией в качестве платежной организации, присвоен ' +
      'регистрационный номер № 02-22-142 и записан в Реестр платежных организаций.' +
      '© 2024 ® Newpay. Все права защищены."'
  }
});

export const getOneCreditContentData = (): { header: { icon: any; title: string }; main: Record<string, never> } => ({
  header: {
    title: 'OneCredit',
    icon: logoOneCredit
  },
  main: {}
});

export const getCredit365ContentData = (): { header: { icon: any; title: string }; main: Record<string, never> } => ({
  header: {
    title: 'Credit365',
    icon: credit365Logo
  },
  main: {}
});

export const getInstallmentContentData = (): { header: { icon: any; title: string }; main: Record<string, never> } => ({
  header: {
    title: 'Installment',
    icon: logoInstallment
  },
  main: {}
});

export const getAutomoneyContentData = (): { header: { icon: any; title: string }; main: Record<string, never> } => ({
  header: {
    title: 'Automoney',
    icon: customLogo
  },
  main: {}
});
