import './style.scss';

type UserAgreementProps = {
  textButton?: string;
};

const UserAgreement = (props: UserAgreementProps) => {
  const { textButton = 'Подтвердить' } = props;
  return (
    <div className="user-agreement">
      <p className="user-agreement__text">
        Нажимая кнопку “{textButton}”, вы соглашаетесь с соглашениями&nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=1"
          className="user-agreement__link"
        >
          Публичного договора,
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=2"
          className="user-agreement__link"
        >
          Политикой конфиденциальности,
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=3"
          className="user-agreement__link"
        >
          Публичной офертой для приема платежей с карт,
        </a>
      </p>
    </div>
  );
};

export default UserAgreement;
